import React, { useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { SnowOverlay } from 'react-snow-overlay';

const App = () => {
  const [selectedMode, setSelectedMode] = useState('typo');
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [retryMode, setRetryMode] = useState(false);
  const modeDescriptions = {
    typo: "Our innovative and powerful tek. Type any message and we will insert a typo into it.",
    claim: 'We are airdropping 1 $SOL to all first-time users of our site, type "claim" to receive instructions',
    donate: 'As a community, we strongly believe in giving back help us sponsor a child in need by typing "donate"!',
  };


  const handleModeChange = (newMode) => {
    setSelectedMode(newMode);
    setInput('');
    setOutput('');
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (retryMode) {
      handleRetry();
    } else if (e.key === 'Enter') {
      const trimmedInput = input.trim();
      setInput(trimmedInput);
      processInput(trimmedInput);
    }
  };
  
  const processInput = (trimmedInput) => {
    setOutput('');
    if (selectedMode === 'typo') {
      setOutput(applyRandomTypo(trimmedInput));
      setInput('');
    } else if (selectedMode === 'claim') {
      if (trimmedInput.toLowerCase() === 'claim') {
        setOutput('You entered: ' + applyRandomTypo(trimmedInput) + " - Incorrect! It's spelled c-l-a-i-m, don't you want free $SOL?");
      } else {
        setOutput(trimmedInput + ' - Incorrect! Try again.');
      }
      setInput('');
    } else if (selectedMode === 'donate') {
      if (trimmedInput.toLowerCase() === 'donate') {
        setOutput('You entered: ' + applyRandomTypo(trimmedInput) + ' - All you have to do is type "donate". Why do you refuse to help a child?');
      } else {
        setOutput(trimmedInput + ' - Try again.');
      }
      setInput('');
    }
    setRetryMode(true);
  };
  
  const handleRetry = () => {
    setRetryMode(false); // Disable retry mode
    setInput('');        // Clear input field
         // Clear output field
  };

  const applyRandomTypo = (text) => {
    if (text.length === 0) return '';
    const typoIndex = Math.floor(Math.random() * text.length);
    let newChar = text[typoIndex];
    while (newChar === text[typoIndex] || newChar === ' ') {
      newChar = randomChar();
    }
    return (
      text.slice(0, typoIndex) + newChar + text.slice(typoIndex + 1)
    );
  };

  const randomChar = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyz';
    return chars[Math.floor(Math.random() * chars.length)];
  };

  return (
    <div className="page-container">
      {/* Header */}
      <header className="header">
        <SnowOverlay color="rgba(254, 166, 128, 1)" />
        <h1>A Typo Mistake</h1>
        <p>CA: BWShf6Rftw4Uzjz7METjsS6p4fVXkHvP46P77wLwy2mD</p>
      </header>

      {/* Main Content */}
      <main className="main-content">
        {/* Button Container */}
        <div className="button-container">
          <button
            className={selectedMode === 'typo' ? 'active' : ''}
            onClick={() => handleModeChange('typo')}>Typo Tek</button>
          <button 
            className={selectedMode === 'claim' ? 'active' : ''}
            onClick={() => handleModeChange('claim')}>Claim Free Airdrop</button>
          <button 
            className={selectedMode === 'donate' ? 'active' : ''}
            onClick={() => handleModeChange('donate')}>Help a Child</button>
          <button
            className="disabled"
            disabled>
            Gallery (Coming soon!)
            </button>
        </div>

        {/* Mode Description */}
        <div className="mode-description">
          <p>{modeDescriptions[selectedMode]}</p>
        </div>

        {/* Input Field */}
        <div className="input-container">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder="Type here..."
          />
        </div>

        {/* Output */}
        <div className="output-container">
          <p>{output}</p>
        </div>
      </main>

    <footer className="footer">
      <p>&copy; 2024-2025 A Typo Mistake - All Rights <s>Reversed</s> Reserved</p>
      <a
        href="https://x.com/atypomistake"
        target="_blank"
        rel="noopener noreferrer"
        className="twitter-icon"
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
    </footer>
  </div>
  );
};

export default App;


